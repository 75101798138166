import { lazy } from "react";
import api from "../../pages/api/pages";

const Home = lazy(() => import("../../pages/home/Home"));
const Api = lazy(() => import("../../pages/api/Api"));
const Downloads = lazy(() => import("../../pages/downloads/Downloads"));
const OsDownloads = lazy(() => import("../../pages/downloads/pages/os/Os"));
const Q2FkZW4gRmlua2Vsc3RlaW4 = lazy(() =>
  import("../../pages/Q2FkZW4gRmlua2Vsc3RlaW4/Q2FkZW4gRmlua2Vsc3RlaW4")
);
const Modding = lazy(() => import("../../pages/modding/Modding"));
const Spt = lazy(() => import("../../pages/modding/pages/spt/Spt"));
const ModPacks = lazy(() =>
  import("../../pages/modding/pages/spt/pages/modpacks/ModPacks")
);
const Resources = lazy(() =>
  import("../../pages/modding/pages/spt/pages/resources/Resources")
);
const Ads = lazy(() => import("../../pages/ads/Ads"));
const Boyfriend = lazy(() =>
  import("../../pages/ads/pages/boyfriend/Boyfriend")
);
const Auth = lazy(() => import("../../pages/auth/Auth"));
const Press = lazy(() => import("../../pages/press/Press"));
const Github = lazy(() => import("../../pages/github/Github"));
const GithubAuth = lazy(() =>
  import("../../pages/github//pages/auth/GithubAuth")
);
const Dev = lazy(() => import("../../pages/dev/Dev"));
const DevLocal = lazy(() => import("../../pages/dev/pages/local/DevLocal"));
const Search = lazy(() => import("../../pages/search/Search"));
const Art = lazy(() => import("../../pages/art/Art"));

export {
  Home,
  Api,
  api,
  Downloads,
  OsDownloads,
  Q2FkZW4gRmlua2Vsc3RlaW4,
  Modding,
  Spt,
  ModPacks,
  Resources,
  Ads,
  Boyfriend,
  Press,
  Github,
  GithubAuth,
  Auth,
  Dev,
  DevLocal,
  Search,
  Art,
};
