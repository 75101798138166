import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import Header from "./Header";
import UserAgentDisplay from "./UserAgentDisplay";

const Layout = () => {
  const location = useLocation();

  const showHeader = location.pathname === "/";

  return (
    <>
      {showHeader && <Header inPage={true} />}
      <UserAgentDisplay />
      <div className="container">
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
