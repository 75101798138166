import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink, Element } from "react-scroll";
import PropTypes from "prop-types";
import colors from "../../data/colors.data";

import {
  menu,
  github,
  linkedinRoundWhite,
  logo8Large,
  mainLogo,
  mainLogoLarge,
  mainLogoFullNameLarge,
} from "../../assets/images/svgs/icons";

const Header = ({ inPage = true }) => {
  const [isNavigationActive, setIsNavigationActive] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [currentLogo, setCurrentLogo] = useState(mainLogo);

  const location = useLocation();
  const currentPath = location.pathname.endsWith("/")
    ? location.pathname
    : location.pathname + "/";

  const toggleNavigation = () => {
    setIsNavigationActive(!isNavigationActive);
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        setIsMenu(true);
      } else {
        setIsMenu(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenu]);
  useEffect(() => {
    const logos = [mainLogo, mainLogoLarge, mainLogoFullNameLarge, logo8Large];
    setCurrentLogo(logos[Math.floor(Math.random() * logos.length)]);
  }, []);
  return (
    <>
      {inPage ? (
        <Element name="header">
          <header className="header" id="header">
            <div className="header-logo">
              <Link to="/" title="Go to main page" aria-label="Go to main page">
                <img src={currentLogo} alt="Code End Tag Logo" loading="lazy" />
              </Link>
            </div>
            <ul
              className={`header-navigation ${
                isNavigationActive ? "active" : ""
              }`}
            >
              <li className="navigation-item">
                <ScrollLink
                  to="header"
                  smooth={true}
                  duration={500}
                  className="item-link"
                >
                  Home
                </ScrollLink>
              </li>
              <li className="navigation-item">
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  className="item-link"
                >
                  About
                </ScrollLink>
              </li>
              <li className="navigation-item">
                <ScrollLink
                  to="works"
                  smooth={true}
                  duration={500}
                  className="item-link"
                >
                  Works
                </ScrollLink>
              </li>
              <li className="navigation-item">
                <ScrollLink
                  to="contact"
                  smooth={true}
                  duration={500}
                  className="item-link"
                >
                  Contact
                </ScrollLink>
              </li>
              <li className="navigation-item">
                <ScrollLink
                  to="tools"
                  smooth={true}
                  duration={500}
                  className="item-link"
                >
                  Tools
                </ScrollLink>
              </li>
              <li className="navigation-item">
                <ScrollLink
                  to="ads"
                  smooth={true}
                  duration={500}
                  className="item-link"
                >
                  Ads
                </ScrollLink>
              </li>
            </ul>
            <div className="header-socials">
              <a
                href="https://www.linkedin.com/in/caden-finkelstein-67239528b/"
                title="Go to my LinkedIn page"
                aria-label="Go to my LinkedIn page"
              >
                <img
                  src={linkedinRoundWhite}
                  id="LinkedIn"
                  alt="LinkedIn"
                  loading="lazy"
                />
              </a>
              <a
                href="https://github.com/MysticalMike60t"
                title="Go to my GitHub page"
                aria-label="Go to my GitHub page"
              >
                <img src={github} id="Github" alt="Github" loading="lazy" />
              </a>
            </div>
            <div className="header-toggle">
              <button className="toggle-button" onClick={toggleNavigation}>
                <img src={menu} alt="Hamburger Menu Icon" loading="lazy" />
              </button>
            </div>
          </header>
        </Element>
      ) : (
        <Element name="header">
          <header className="header" id="header">
            <div className="header-logo">
              <Link to="/" title="Go to home page" aria-label="Go to home page">
                <img src={currentLogo} alt="Code End Tag Logo" loading="lazy" />
              </Link>
            </div>
            <ul
              className={`header-navigation ${
                isNavigationActive ? "active" : ""
              }`}
            >
              <li className="navigation-item">
                <a
                  href="/"
                  className="item-link"
                  style={{
                    color: `${
                      currentPath === "/" ? colors.primary : "default"
                    }`,
                  }}
                  title="Go to Home"
                  aria-label="Go to Home"
                >
                  Home
                </a>
              </li>
              <li className="navigation-item">
                <a
                  href="/api"
                  className="item-link"
                  style={{
                    color: `${
                      currentPath === "/api/" ? colors.primary : "default"
                    }`,
                  }}
                  title="Go to Api"
                  aria-label="Go to Api"
                >
                  Api
                </a>
              </li>
              <li className="navigation-item">
                <a
                  href="/downloads"
                  className="item-link"
                  style={{
                    color:
                      currentPath === "/downloads/" && !isMenu
                        ? colors.primary
                        : undefined,
                  }}
                  title="Go to Downloads"
                  aria-label="Go to Downloads"
                >
                  Downloads
                </a>
              </li>
              <li className="navigation-item">
                <a
                  href="/modding"
                  className="item-link"
                  style={{
                    color:
                      currentPath === "/modding/" && !isMenu
                        ? colors.primary
                        : undefined,
                  }}
                  title="Go to Modding"
                  aria-label="Go to Modding"
                >
                  Modding
                </a>
              </li>
              <li className="navigation-item">
                <a
                  href="/press"
                  className="item-link"
                  style={{
                    color:
                      currentPath === "/press/" && !isMenu
                        ? colors.primary
                        : undefined,
                  }}
                  title="Go to Press"
                  aria-label="Go to Press"
                >
                  Press
                </a>
              </li>
              <li className="navigation-item">
                <a
                  href={
                    localStorage.getItem("continue") === "true"
                      ? "/github"
                      : "/github/auth"
                  }
                  className="item-link"
                  style={{
                    color:
                      (currentPath === "/github/auth/" ||
                        currentPath === "/github/") &&
                      !isMenu
                        ? colors.primary
                        : undefined,
                  }}
                  title="Go to GitHub"
                  aria-label="Go to GitHub"
                >
                  GitHub
                </a>
              </li>
            </ul>
            <div className="header-socials">
              <a
                href="https://www.linkedin.com/in/caden-finkelstein-67239528b/"
                target="_blank"
                rel="noopen noreferrer"
                title="My LinkedIn Page"
                aria-label="Go to LinkedIn"
              >
                <img
                  src={linkedinRoundWhite}
                  id="LinkedIn"
                  alt="LinkedIn"
                  loading="lazy"
                />
              </a>
              <a
                href="https://github.com/MysticalMike60t"
                target="_blank"
                rel="noopen noreferrer"
                title="My GitHub Page"
                aria-label="Go to GitHub"
              >
                <img src={github} id="Github" alt="Github" loading="lazy" />
              </a>
            </div>
            <div className="header-toggle">
              <button className="toggle-button" onClick={toggleNavigation}>
                <img src={menu} alt="Hamburger Menu Icon" loading="lazy" />
              </button>
            </div>
          </header>
        </Element>
      )}
    </>
  );
};

Header.propTypes = {
  inPage: PropTypes.bool,
};

export default Header;
