import React, { useEffect, useState } from "react";
import pageDefaults from "../../../../data/pageDefaults.data";
import cms from "../../../../global/functions/cms";
import { UpdatePageMeta } from "../../../../global/modules";
import { Header } from "../../../../global/components";

const QuillCheat = () => {
  const [pageTitle, setPageTitle] = useState(pageDefaults.titles.modding.main);

  const pageNameText = "quill-cheat";
  const pageName = pageNameText.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pageDefaultsData = await cms("page-defaults");
        setPageTitle(pageDefaultsData.titles.modding.main);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <UpdatePageMeta
        title={pageTitle}
        description={pageDefaults.descriptions.modding.main}
        path={"/" + pageName}
      />
      <Header inPage={false} />
      <div className="page-container api sub-page quill-cheat">
        <div className="wrapper">
          <div className="title-wrapper">
            <h1>Quill Cheat</h1>
            <span>Made for Tampermonkey</span>
          </div>
          <div className="info">
            Copy{" "}
            <a
              className="link"
              href="https://github.com/MysticalMike60t/quill-cheat/blob/main/code.js"
            >
              this
            </a>{" "}
            code into Tampermonkey
          </div>
        </div>
      </div>
    </>
  );
};

export default QuillCheat;
